const toastr = require('toastr');
// const bootbox = require('bootbox');
// $(document).on('change input','input.error,textarea.error',function(){
//     $(this).removeClass('error');
//     $(this).parent().find('label.error').remove();
// });




$('.with-popup').on('click', function (e) {
    e.preventDefault();
    var $this = $(this).data('submit');
    var redirect = $(this).data('redirect') ?? false;
    var refresh = $(this).data('refresh') ?? false;
    var resetForm = $(this).data('reset') ?? false;
    var close = $(this).data('close') ?? false;
    $('#'+$this+' input.error').removeClass('error');
    $('#'+$this+' textarea.error').removeClass('error');
    $('#'+$this+' label.error').remove();
    $('#'+$this+' a.error-tab').removeClass('error-tab');

    const form = $(this).closest('form');
    const phoneInput = form.find('.phone-numero');
    const regex = /\+38\(\d{3}\)\d{3}-\d{2}-\d{2}/;
    const phoneValid = regex.test(phoneInput.val());
    if (!phoneValid) {
        phoneInput.focus();
        return;
    }

    $.ajax({
        type: "POST",
        url: $('#' + $this).data('action'),
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: "JSON",
        data: new FormData(document.getElementById($this)),
        processData: false,
        contentType: false,

        success: function (response) {
            if(response.redirect) redirect = response.redirect;
            if(response.status) toastr.success(response.message);
            else toastr.error(response.message);
            if (response.open) {
                window.open(response.open,'_blank');
            }
            else if (response.status == true) {

                setTimeout(function () {

                    if (redirect) {
                        if(redirect == "self") {
                            window.location.reload();
                        } else {
                            window.location.href = redirect;
                        }
                    }
                }, 200);

                if (resetForm) {
                    $('#'+$this)[0].reset();
                }
                if (close) {
                    $('#'+close).modal('toggle');
                }
            }
        },
        error: function (request) {
            let data = JSON.parse(request.responseText);
            let hier = 0;
            for (var key in data.errors) {
                let name = '';
                let element = 0;
                let loc = '';
                if (key.indexOf('.') !== -1) {
                    let code = key.split('.');
                    $(code).each(function () {
                        if (this == 'ru' || this == 'en') {
                            loc = this;

                        }
                        if (name === '') {
                            name = this;
                        } else {
                            if ($('[name^="' + name + '[' + this + ']' + '"]').length === 0) {
                                name = name + '[]';
                                element = this;
                            } else {
                                name = name + '[' + this + ']';
                            }
                        }

                    });
                } else {
                    name = key;
                }
                $('#'+$this+' [name="' + name + '"]').eq(element).addClass('error');
                $('#' + $this + ' [name="' + name + '"]').eq(element).parent().append('<label class="error">' + data.errors[key][0] + '</label>');
            }
            toastr.error(data.message ?? $('#submit_error').text());
        },
    });
});

