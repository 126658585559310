import $ from 'jquery';
window.$ = window.jQuery = $;

require('./modules/forms');

require('./modules/calculateTax');

require('./modules/calculateFee');

import { isWebp, menuInit, isSlider, isTabs, isSelect, isSwiper, isSwiper2, isSwiper3, toTop, isSwiper4,  } from "./modules/functions.js";
import { DualHRangeBar, DualVRangeBar } from 'dual-range-bar';
import Swiper , { Navigation, Pagination }  from 'swiper';
import IMask from 'imask';
import 'swiper/css';


require('./modules/functions');

isWebp();

menuInit();

isSlider();

isSwiper2();

isSwiper3();

// isSwiper4();

isTabs();

isSelect();

isSwiper();

toTop();

const drbarContainer = document.getElementById('my-drbar-container');
if (drbarContainer) {
    const drbar = new DualHRangeBar('my-drbar-container', {
        lowerBound: 1950,    // Initial value for "lowerBound"
        upperBound: 2022,    // Initial value for "upperBound"
        lower: 1950,         // Initial value for "lower"
        upper: 2022,         // Initial value for "upper"
        minSpan: 0,
        sliderColor: '#1E88A8', // Color of the slider
        sliderActive: '#08789b',// Slider color when active
        rangeColor: '#7DB9DE',  // Color of the range slider
        rangeActive: '#5da8d6', // Range slider color when active
        bgColor: '#aaaaaa44',   // Color of the background
    })

    drbar.addEventListener ('update',  ( e )  =>  {
        let yearsLower = document.getElementById('lowerYears')
        let yearsUpper = document.getElementById('upperYears')

        yearsLower.value = (drbar.lower).toFixed(0)

        yearsUpper.value = (drbar.upper).toFixed(0)
    })
}

const drbarContainer2 = document.getElementById('my-drbar-container2');
if (drbarContainer2) {
    const drbar2 = new DualHRangeBar('my-drbar-container2', {
        lowerBound: 0,    // Initial value for "lowerBound"
        upperBound: 1000000,    // Initial value for "upperBound"
        lower: 0,         // Initial value for "lower"
        upper: 1000000,         // Initial value for "upper"
        minSpan: 0,
        sliderColor: '#1E88A8', // Color of the slider
        sliderActive: '#08789b',// Slider color when active
        rangeColor: '#7DB9DE',  // Color of the range slider
        rangeActive: '#5da8d6', // Range slider color when active
        bgColor: '#aaaaaa44',   // Color of the background
    })

    drbar2.addEventListener ('update',  ( e )  =>  {
        let priceLower = document.getElementById('lowerPrice')
        let priceUpper = document.getElementById('upperPrice')

        priceLower.value = (drbar2.lower).toFixed(0)

        priceUpper.value = (drbar2.upper).toFixed(0)
    })
}

const swiper = new Swiper('.swiper', {
    modules: [ Navigation, Pagination ],
    // Default parameters
    slidesPerView: 3,
    spaceBetween: 10,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px

        // when window width is >= 480px
        300:{
            slidesPerView: 1,
            spaceBetween: 20,
        },

        768: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        991: {
            slidesPerView: 2,
            spaceBetween: 40
        },
        1100: {
            slidesPerview: 3,
            spaceBetween: 40,
        }
    }
    })


const anchors = document.querySelectorAll('a[href*="#"]')

 for (let anchor of anchors) {
 anchor.addEventListener('click', function (e) {
  e.preventDefault()

  const blockID = anchor.getAttribute('href').substr(1)

  document.getElementById(blockID).scrollIntoView({
  behavior: 'smooth',
  block: 'start'
  })
 })
 }


let showMoreBtn = document.getElementById('show-more')

if( showMoreBtn ){
    showMoreBtn.addEventListener( 'click', () => {
        let hiddenElements = document.querySelectorAll(' .catalog__more .more')

        let commentsList = Array.prototype.slice.call(hiddenElements).slice(0, 3);

        commentsList.forEach( (item, index) => {
            item.className += 'static'
            item.classList.remove('more');

            if(index === 0){
                item.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        })

        console.log(hiddenElements.length)

        if (hiddenElements.length <= 3) document.getElementById('show-more').style.display = 'none';

        if(hiddenElements.length === 0){
            showMoreBtn.className = '.more'
        }

    } )
}


function phoneMask(){

    const phoneInputs = document.querySelectorAll('.phone-numero');

    // Создание маски для украинского номера телефона
    if(phoneInputs){
        const phoneMaskOptions = {
            mask: '+{38}(000)000-00-00',
          };

          phoneInputs.forEach(input => {
            IMask(input, phoneMaskOptions)
          });
    }


}

phoneMask()




document.addEventListener('DOMContentLoaded', function() {
    // Отримання елементу стрічки
    const marquee = document.querySelector('.marquee span');
    if (marquee) {
        // Функція для запуску анімації бігучої стрічки
        function animateMarquee() {
            const marqueeWidth = marquee.offsetWidth;
            const containerWidth = document.querySelector('.marquee').offsetWidth;
            const animationDuration = (marqueeWidth + containerWidth) / 50; // Розрахунок тривалості анімації

            marquee.style.animation = `marquee ${animationDuration}s linear infinite`; // Запуск анімації
        }

        // Виклик функції для анімації стрічки
        animateMarquee();
    }
});

