import Swiper , { Navigation, Pagination }  from 'swiper';
import Select from 'select-custom';

import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

/* Проверка поддержки webp, добавление класса webp или no-webp для HTML */
export function isWebp() {
	// Проверка поддержки webp
	function testWebP(callback) {
		let webP = new Image();
		webP.onload = webP.onerror = function () {
			callback(webP.height == 2);
		};
		webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
	}
	// Добавление класса _webp или _no-webp для HTML
	testWebP(function (support) {
		let className = support === true ? 'webp' : 'no-webp';
		document.documentElement.classList.add(className);
	});
}

export function menuInit() {
	let iconMenu = document.querySelector('.icon-menu')
	let menuBody = document.querySelector('.menu__body')
    if (!iconMenu || !menuBody) {
        return
    }

	iconMenu.addEventListener('click', () => {
		iconMenu.classList.toggle('_active')
		menuBody.classList.toggle('body_active')

	})
	menuBody.addEventListener('click', (event) => {
		if (!menuBody.classList.contains('body_active') || event.target.classList.contains('menu__body-content')) {
			// console.log('hahahah');
			return;
		}
		iconMenu.classList.toggle('_active')
		menuBody.classList.toggle('body_active')
		// console.log('hui')
	})

}




export function isSwiper() {

	let sliders = document.querySelectorAll('._swiper');
	if (sliders) {
		for (let index = 0; index < sliders.length; index++) {
			let slider = sliders[index];
			if (!slider.classList.contains('swiper-bild')) {
				let slider_items = slider.children;
				if (slider_items) {
					for (let index = 0; index < slider_items.length; index++) {
						let el = slider_items[index];
						el.classList.add('swiper-slide');
					}
				}
				let slider_content = slider.innerHTML;
				let slider_wrapper = document.createElement('div');
				slider_wrapper.classList.add('swiper-wrapper');
				slider_wrapper.innerHTML = slider_content;
				slider.innerHTML = '';
				slider.appendChild(slider_wrapper);
				slider.classList.add('swiper-bild');

				if (slider.classList.contains('_swiper_scroll')) {
					let sliderScroll = document.createElement('div');
					sliderScroll.classList.add('swiper-scrollbar');
					slider.appendChild(sliderScroll);
				}
			}
			if (slider.classList.contains('_gallery')) {
				//slider.data('lightGallery').destroy(true);
			}
		}
		sliders_bild_callback();
	}

	function sliders_bild_callback(params) {}
	let sliderScrollItems = document.querySelectorAll('._swiper_scroll');
	if (sliderScrollItems.length > 0) {
		for (let index = 0; index < sliderScrollItems.length; index++) {
			const sliderScrollItem = sliderScrollItems[index];
			const sliderScrollBar = sliderScrollItem.querySelector('.swiper-scrollbar');
			const sliderScroll = new Swiper(sliderScrollItem, {
				observer: true,
				observeParents: true,
				direction: 'vertical',
				slidesPerView: 'auto',
				freeMode: true,
				scrollbar: {
					el: sliderScrollBar,
					draggable: true,
					snapOnRelease: false
				},
				mousewheel: {
					releaseOnEdges: true,
				},
			});
			sliderScroll.scrollbar.updateSize();
		}
	}
	const swiper4 = new Swiper('.auction__content', {
		modules: [Navigation, Pagination],
		/*
		effect: 'fade',
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		*/
		// observer: true,
		// observeParents: true,
		slidesPerView: 1,
		spaceBetween: 30,
		grabCursor: true,
		autoHeight: false,
		speed: 800,
		//touchRatio: 0,
		//simulateTouch: false,
		loop: false,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		//preloadImages: false,
		//lazy: true,
		// Dotts
		pagination: {
			el: '.swiper-pagination-1',
			clickable: true,
			// dynamicBullets: true,
		},
		//Arrows
		// navigation: {
		// 	nextEl: '.control-main-slider__arrow_next',
		// 	prevEl: '.control-main-slider__arrow_prev',
		// },

		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 30,
				autoHeight: true,
			},
			555:{
				slidesPerView: 2,
				spaceBetween: 30,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			992: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
			1268: {
				slidesPerView: 4,
				spaceBetween: 30,
			},
		},

		on: {
			lazyImageReady: function () {
				ibg();
			},
		}
		// And if we need scrollbar
		//scrollbar: {
		//	el: '.swiper-scrollbar',
		//},
	});

}

export function isSwiper2(){
	let sliders = document.querySelectorAll('._swiper');
	if (sliders) {
		for (let index = 0; index < sliders.length; index++) {
			let slider = sliders[index];
			if (!slider.classList.contains('swiper-bild')) {
				let slider_items = slider.children;
				if (slider_items) {
					for (let index = 0; index < slider_items.length; index++) {
						let el = slider_items[index];
						el.classList.add('swiper-slide');
					}
				}
				let slider_content = slider.innerHTML;
				let slider_wrapper = document.createElement('div');
				slider_wrapper.classList.add('swiper-wrapper');
				slider_wrapper.innerHTML = slider_content;
				slider.innerHTML = '';
				slider.appendChild(slider_wrapper);
				slider.classList.add('swiper-bild');

				if (slider.classList.contains('_swiper_scroll')) {
					let sliderScroll = document.createElement('div');
					sliderScroll.classList.add('swiper-scrollbar');
					slider.appendChild(sliderScroll);
				}
			}
			if (slider.classList.contains('_gallery')) {
				//slider.data('lightGallery').destroy(true);
			}
		}
		sliders_bild_callback();
	}

	function sliders_bild_callback(params) {}
	let sliderScrollItems = document.querySelectorAll('._swiper_scroll');
	if (sliderScrollItems.length > 0) {
		for (let index = 0; index < sliderScrollItems.length; index++) {
			const sliderScrollItem = sliderScrollItems[index];
			const sliderScrollBar = sliderScrollItem.querySelector('.swiper-scrollbar');
			const sliderScroll = new Swiper(sliderScrollItem, {
				observer: true,
				observeParents: true,
				direction: 'vertical',
				slidesPerView: 'auto',
				freeMode: true,
				scrollbar: {
					el: sliderScrollBar,
					draggable: true,
					snapOnRelease: false
				},
				mousewheel: {
					releaseOnEdges: true,
				},
			});
			sliderScroll.scrollbar.updateSize();
		}
	}
	const swiper2 = new Swiper('.catalog__mobile-cart', {
		modules: [Navigation, Pagination],
		/*
		effect: 'fade',
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		*/
		// observer: true,
		// observeParents: true,
		slidesPerView: 1,
		spaceBetween: 20,
		grabCursor: true,
		//autoHeight: true,
		speed: 800,
		//touchRatio: 0,
		//simulateTouch: false,
		loop: false,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		//preloadImages: false,
		//lazy: true,
		// Dotts
		pagination: {
			el: '.swiper-pagination2',
			clickable: true,
			dynamicBullets: true,
		},
		// Arrows
		// navigation: {
		// 	nextEl: '.control-main-slider__arrow_next',
		// 	prevEl: '.control-main-slider__arrow_prev',
		// },

		// breakpoints: {
		// 	320: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 		autoHeight: true,
		// 	},
		// 	555:{
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 	},
		// 	768: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 	},
		// 	992: {
		// 		slidesPerView: 4,
		// 		spaceBetween: 20,
		// 	},
		// 	1268: {
		// 		slidesPerView: 4,
		// 		spaceBetween: 30,
		// 	},
		// },

		// on: {
		// 	lazyImageReady: function () {
		// 		ibg();
		// 	},
		// }
		// And if we need scrollbar
		//scrollbar: {
		//	el: '.swiper-scrollbar',
		//},
	});
}

export function isSwiper3(){
	let sliders = document.querySelectorAll('._swiper');
	if (sliders) {
		for (let index = 0; index < sliders.length; index++) {
			let slider = sliders[index];
			if (!slider.classList.contains('swiper-bild')) {
				let slider_items = slider.children;
				if (slider_items) {
					for (let index = 0; index < slider_items.length; index++) {
						let el = slider_items[index];
						el.classList.add('swiper-slide');
					}
				}
				let slider_content = slider.innerHTML;
				let slider_wrapper = document.createElement('div');
				slider_wrapper.classList.add('swiper-wrapper');
				slider_wrapper.innerHTML = slider_content;
				slider.innerHTML = '';
				slider.appendChild(slider_wrapper);
				slider.classList.add('swiper-bild');

				if (slider.classList.contains('_swiper_scroll')) {
					let sliderScroll = document.createElement('div');
					sliderScroll.classList.add('swiper-scrollbar');
					slider.appendChild(sliderScroll);
				}
			}
			if (slider.classList.contains('_gallery')) {
				//slider.data('lightGallery').destroy(true);
			}
		}
		sliders_bild_callback();
	}

	function sliders_bild_callback(params) {}
	let sliderScrollItems = document.querySelectorAll('._swiper_scroll');
	if (sliderScrollItems.length > 0) {
		for (let index = 0; index < sliderScrollItems.length; index++) {
			const sliderScrollItem = sliderScrollItems[index];
			const sliderScrollBar = sliderScrollItem.querySelector('.swiper-scrollbar');
			const sliderScroll = new Swiper(sliderScrollItem, {
				observer: true,
				observeParents: true,
				direction: 'vertical',
				slidesPerView: 'auto',
				freeMode: true,
				scrollbar: {
					el: sliderScrollBar,
					draggable: true,
					snapOnRelease: false
				},
				mousewheel: {
					releaseOnEdges: true,
				},
			});
			sliderScroll.scrollbar.updateSize();
		}
	}
	const swiper3 = new Swiper('.table__swiper', {
		modules: [Navigation, Pagination],
		/*
		effect: 'fade',
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		*/
		// observer: true,
		// observeParents: true,
		slidesPerView: 1,
		spaceBetween: 20,
		grabCursor: true,
		//autoHeight: true,
		speed: 800,
		//touchRatio: 0,
		//simulateTouch: false,
		loop: false,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		//preloadImages: false,
		//lazy: true,
		// Dotts
		pagination: {
			el: '.swiper-pagination3',
			clickable: true,
			dynamicBullets: true,
			renderBullet: function (index, className) {
				return '<span class="' + className + '">' + (index + 1) + "</span>";
			},
		},
		// Arrows
		// navigation: {
		// 	nextEl: '.control-main-slider__arrow_next',
		// 	prevEl: '.control-main-slider__arrow_prev',
		// },

		// breakpoints: {
		// 	320: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 		autoHeight: true,
		// 	},
		// 	555:{
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 	},
		// 	768: {
		// 		slidesPerView: 1,
		// 		spaceBetween: 20,
		// 	},
		// 	992: {
		// 		slidesPerView: 4,
		// 		spaceBetween: 20,
		// 	},
		// 	1268: {
		// 		slidesPerView: 4,
		// 		spaceBetween: 30,
		// 	},
		// },

		// on: {
		// 	lazyImageReady: function () {
		// 		ibg();
		// 	},
		// }
		// And if we need scrollbar
		//scrollbar: {
		//	el: '.swiper-scrollbar',
		//},
	});
}

// export function isSwiper4() {

// 	let sliders = document.querySelectorAll('._swiper');
// 	if (sliders) {
// 		for (let index = 0; index < sliders.length; index++) {
// 			let slider = sliders[index];
// 			if (!slider.classList.contains('swiper-bild')) {
// 				let slider_items = slider.children;
// 				if (slider_items) {
// 					for (let index = 0; index < slider_items.length; index++) {
// 						let el = slider_items[index];
// 						el.classList.add('swiper-slide');
// 					}
// 				}
// 				let slider_content = slider.innerHTML;
// 				let slider_wrapper = document.createElement('div');
// 				slider_wrapper.classList.add('swiper-wrapper');
// 				slider_wrapper.innerHTML = slider_content;
// 				slider.innerHTML = '';
// 				slider.appendChild(slider_wrapper);
// 				slider.classList.add('swiper-bild');

// 				if (slider.classList.contains('_swiper_scroll')) {
// 					let sliderScroll = document.createElement('div');
// 					sliderScroll.classList.add('swiper-scrollbar');
// 					slider.appendChild(sliderScroll);
// 				}
// 			}
// 			if (slider.classList.contains('_gallery')) {
// 				//slider.data('lightGallery').destroy(true);
// 			}
// 		}
// 		sliders_bild_callback();
// 	}

// 	function sliders_bild_callback(params) {}
// 	let sliderScrollItems = document.querySelectorAll('._swiper_scroll');
// 	if (sliderScrollItems.length > 0) {
// 		for (let index = 0; index < sliderScrollItems.length; index++) {
// 			const sliderScrollItem = sliderScrollItems[index];
// 			const sliderScrollBar = sliderScrollItem.querySelector('.swiper-scrollbar');
// 			const sliderScroll = new Swiper(sliderScrollItem, {
// 				observer: true,
// 				observeParents: true,
// 				direction: 'vertical',
// 				slidesPerView: 'auto',
// 				freeMode: true,
// 				scrollbar: {
// 					el: sliderScrollBar,
// 					draggable: true,
// 					snapOnRelease: false
// 				},
// 				mousewheel: {
// 					releaseOnEdges: true,
// 				},
// 			});
// 			sliderScroll.scrollbar.updateSize();
// 		}
// 	}
// 	const swiper4 = new Swiper('.choise__content', {
// 		modules: [Navigation, Pagination],
// 		/*
// 		effect: 'fade',
// 		autoplay: {
// 			delay: 3000,
// 			disableOnInteraction: false,
// 		},
// 		*/
// 		// observer: true,
// 		// observeParents: true,
// 		// centeredSlides: true,
// 		initialSlide: 0,
// 		slidesPerView: 1,
// 		spaceBetween: 30,
// 		// grabCursor: true,
// 		// centerInsufficientSlides: true,
// 		//autoHeight: true,
// 		// speed: 800,
// 		//touchRatio: 0,
// 		//simulateTouch: false,
// 		// loop: false,
// 		// autoplay: {
// 		// 	delay: 5000,
// 		// 	disableOnInteraction: false,
// 		// },
// 		//preloadImages: false,
// 		//lazy: true,
// 		// Dotts
// 		pagination: {
// 			el: '.swiper-pagination4',
// 			clickable: true,
// 			// dynamicBullets: true,
// 		},
// 		//Arrows
// 		// navigation: {
// 		// 	nextEl: '.control-main-slider__arrow_next',
// 		// 	prevEl: '.control-main-slider__arrow_prev',
// 		// },

// 		breakpoints: {
// 			768: {
// 				slidesPerView: 1,
// 				spaceBetween: 30,
// 				centeredSlides: true,
// 			},
// 			992: {
// 				slidesPerView: 3,
// 				spaceBetween: 30,
// 			},
// 			1268: {
// 				slidesPerView: 3,
// 				spaceBetween: 30,
// 			},
// 		},

// 		on: {
// 			lazyImageReady: function () {
// 				ibg();
// 			},
// 		}
// 		// And if we need scrollbar
// 		//scrollbar: {
// 		//	el: '.swiper-scrollbar',
// 		//},
// 	});
// }

export function isSelect(){
	const selects = [...document.querySelectorAll('.js-select')];

	selects.forEach((el) => {

	const select = new Select(el, {
			optionBuilder: undefined,
			panelItem: { position: '', item: '', className: '' },
			changeOpenerText: true,
			multipleSelectionOnSingleClick: true,
			multipleSelectOpenerText: { labels: false, array: false },
			allowPanelClick: false,
			openOnHover: false,
			closeOnMouseleave: false,
			wrapDataAttributes: false,
			openerLabel: false,
	});
	select.init();
	});
}


export function isSpoller() {
	const spollersArray = document.querySelectorAll('[data-spollers]');
	if (spollersArray.length > 0) {
		// Получение обычных слойлеров
		const spollersRegular = Array.from(spollersArray).filter(function (item, index, self) {
			return !item.dataset.spollers.split(",")[0];
		});
		// Инициализация обычных слойлеров
		if (spollersRegular.length > 0) {
			initSpollers(spollersRegular);
		}

		// Получение слойлеров с медиа запросами
		const spollersMedia = Array.from(spollersArray).filter(function (item, index, self) {
			return item.dataset.spollers.split(",")[0];
		});

		// Инициализация слойлеров с медиа запросами
		if (spollersMedia.length > 0) {
			const breakpointsArray = [];
			spollersMedia.forEach(item => {
				const params = item.dataset.spollers;
				const breakpoint = {};
				const paramsArray = params.split(",");
				breakpoint.value = paramsArray[0];
				breakpoint.type = paramsArray[1] ? paramsArray[1].trim() : "max";
				breakpoint.item = item;
				breakpointsArray.push(breakpoint);
			});

			// Получаем уникальные брейкпоинты
			let mediaQueries = breakpointsArray.map(function (item) {
				return '(' + item.type + "-width: " + item.value + "px)," + item.value + ',' + item.type;
			});
			mediaQueries = mediaQueries.filter(function (item, index, self) {
				return self.indexOf(item) === index;
			});

			// Работаем с каждым брейкпоинтом
			mediaQueries.forEach(breakpoint => {
				const paramsArray = breakpoint.split(",");
				const mediaBreakpoint = paramsArray[1];
				const mediaType = paramsArray[2];
				const matchMedia = window.matchMedia(paramsArray[0]);

				// Объекты с нужными условиями
				const spollersArray = breakpointsArray.filter(function (item) {
					if (item.value === mediaBreakpoint && item.type === mediaType) {
						return true;
					}
				});
				// Событие
				matchMedia.addListener(function () {
					initSpollers(spollersArray, matchMedia);
				});
				initSpollers(spollersArray, matchMedia);
			});
		}
		// Инициализация
		function initSpollers(spollersArray, matchMedia = false) {
			spollersArray.forEach(spollersBlock => {
				spollersBlock = matchMedia ? spollersBlock.item : spollersBlock;
				if (matchMedia.matches || !matchMedia) {
					spollersBlock.classList.add('_init');
					initSpollerBody(spollersBlock);
					spollersBlock.addEventListener("click", setSpollerAction);
				} else {
					spollersBlock.classList.remove('_init');
					initSpollerBody(spollersBlock, false);
					spollersBlock.removeEventListener("click", setSpollerAction);
				}
			});
		}
		// Работа с контентом
		function initSpollerBody(spollersBlock, hideSpollerBody = true) {
			const spollerTitles = spollersBlock.querySelectorAll('[data-spoller]');
			if (spollerTitles.length > 0) {
				spollerTitles.forEach(spollerTitle => {
					if (hideSpollerBody) {
						spollerTitle.removeAttribute('tabindex');
						if (!spollerTitle.classList.contains('_active')) {
							spollerTitle.nextElementSibling.hidden = true;
						}
					} else {
						spollerTitle.setAttribute('tabindex', '-1');
						spollerTitle.nextElementSibling.hidden = false;
					}
				});
			}
		}

		function setSpollerAction(e) {
			const el = e.target;
			if (el.hasAttribute('data-spoller') || el.closest('[data-spoller]')) {
				const spollerTitle = el.hasAttribute('data-spoller') ? el : el.closest('[data-spoller]');
				const spollersBlock = spollerTitle.closest('[data-spollers]');
				const oneSpoller = spollersBlock.hasAttribute('data-one-spoller') ? true : false;
				if (!spollersBlock.querySelectorAll('._slide').length) {
					if (oneSpoller && !spollerTitle.classList.contains('_active')) {
						hideSpollersBody(spollersBlock);
					}
					spollerTitle.classList.toggle('_active');
					_slideToggle(spollerTitle.nextElementSibling, 500);
				}
				e.preventDefault();
			}
		}

		function hideSpollersBody(spollersBlock) {
			const spollerActiveTitle = spollersBlock.querySelector('[data-spoller]._active');
			if (spollerActiveTitle) {
				spollerActiveTitle.classList.remove('_active');
				_slideUp(spollerActiveTitle.nextElementSibling, 500);
			}
		}
	}
	let _slideUp = (target, duration = 500) => {
		if (!target.classList.contains('_slide')) {
			target.classList.add('_slide');
			target.style.transitionProperty = 'height, margin, padding';
			target.style.transitionDuration = duration + 'ms';
			target.style.height = target.offsetHeight + 'px';
			target.offsetHeight;
			target.style.overflow = 'hidden';
			target.style.height = 0;
			target.style.paddingTop = 0;
			target.style.paddingBottom = 0;
			target.style.marginTop = 0;
			target.style.marginBottom = 0;
			window.setTimeout(() => {
				target.hidden = true;
				target.style.removeProperty('height');
				target.style.removeProperty('padding-top');
				target.style.removeProperty('padding-bottom');
				target.style.removeProperty('margin-top');
				target.style.removeProperty('margin-bottom');
				target.style.removeProperty('overflow');
				target.style.removeProperty('transition-duration');
				target.style.removeProperty('transition-property');
				target.classList.remove('_slide');
			}, duration);
		}
	}
	let _slideDown = (target, duration = 500) => {
		if (!target.classList.contains('_slide')) {
			target.classList.add('_slide');
			if (target.hidden) {
				target.hidden = false;
			}
			let height = target.offsetHeight;
			target.style.overflow = 'hidden';
			target.style.height = 0;
			target.style.paddingTop = 0;
			target.style.paddingBottom = 0;
			target.style.marginTop = 0;
			target.style.marginBottom = 0;
			target.offsetHeight;
			target.style.transitionProperty = "height, margin, padding";
			target.style.transitionDuration = duration + 'ms';
			target.style.height = height + 'px';
			target.style.removeProperty('padding-top');
			target.style.removeProperty('padding-bottom');
			target.style.removeProperty('margin-top');
			target.style.removeProperty('margin-bottom');
			window.setTimeout(() => {
				target.style.removeProperty('height');
				target.style.removeProperty('overflow');
				target.style.removeProperty('transition-duration');
				target.style.removeProperty('transition-property');
				target.classList.remove('_slide');
			}, duration);
		}
	}
	let _slideToggle = (target, duration = 500) => {
		if (target.hidden) {
			return _slideDown(target, duration);
		} else {
			return _slideUp(target, duration);
		}
	}
}

export function isDinamicAdaptive() {
	// Dynamic Adapt v.1
	// HTML data-da="where(uniq class name),when(breakpoint),position(digi)"
	// e.x. data-da=".item,992,2"
	// Andrikanych Yevhen 2020
	// https://www.youtube.com/c/freelancerlifestyle

	"use strict";

	function DynamicAdapt(type) {
		this.type = type;
	}

	DynamicAdapt.prototype.init = function () {
		const _this = this;
		// массив объектов
		this.оbjects = [];
		this.daClassname = "_dynamic_adapt_";
		// массив DOM-элементов
		this.nodes = document.querySelectorAll("[data-da]");

		// наполнение оbjects объктами
		for (let i = 0; i < this.nodes.length; i++) {
			const node = this.nodes[i];
			const data = node.dataset.da.trim();
			const dataArray = data.split(",");
			const оbject = {};
			оbject.element = node;
			оbject.parent = node.parentNode;
			оbject.destination = document.querySelector(dataArray[0].trim());
			оbject.breakpoint = dataArray[1] ? dataArray[1].trim() : "767";
			оbject.place = dataArray[2] ? dataArray[2].trim() : "last";
			оbject.index = this.indexInParent(оbject.parent, оbject.element);
			this.оbjects.push(оbject);
		}

		this.arraySort(this.оbjects);

		// массив уникальных медиа-запросов
		this.mediaQueries = Array.prototype.map.call(this.оbjects, function (item) {
			return '(' + this.type + "-width: " + item.breakpoint + "px)," + item.breakpoint;
		}, this);
		this.mediaQueries = Array.prototype.filter.call(this.mediaQueries, function (item, index, self) {
			return Array.prototype.indexOf.call(self, item) === index;
		});

		// навешивание слушателя на медиа-запрос
		// и вызов обработчика при первом запуске
		for (let i = 0; i < this.mediaQueries.length; i++) {
			const media = this.mediaQueries[i];
			const mediaSplit = String.prototype.split.call(media, ',');
			const matchMedia = window.matchMedia(mediaSplit[0]);
			const mediaBreakpoint = mediaSplit[1];

			// массив объектов с подходящим брейкпоинтом
			const оbjectsFilter = Array.prototype.filter.call(this.оbjects, function (item) {
				return item.breakpoint === mediaBreakpoint;
			});
			matchMedia.addListener(function () {
				_this.mediaHandler(matchMedia, оbjectsFilter);
			});
			this.mediaHandler(matchMedia, оbjectsFilter);
		}
	};

	DynamicAdapt.prototype.mediaHandler = function (matchMedia, оbjects) {
		if (matchMedia.matches) {
			for (let i = 0; i < оbjects.length; i++) {
				const оbject = оbjects[i];
				оbject.index = this.indexInParent(оbject.parent, оbject.element);
				this.moveTo(оbject.place, оbject.element, оbject.destination);
			}
		} else {
			for (let i = 0; i < оbjects.length; i++) {
				const оbject = оbjects[i];
				if (оbject.element.classList.contains(this.daClassname)) {
					this.moveBack(оbject.parent, оbject.element, оbject.index);
				}
			}
		}
	};

	// Функция перемещения
	DynamicAdapt.prototype.moveTo = function (place, element, destination) {
		element.classList.add(this.daClassname);
		if (place === 'last' || place >= destination.children.length) {
			destination.insertAdjacentElement('beforeend', element);
			return;
		}
		if (place === 'first') {
			destination.insertAdjacentElement('afterbegin', element);
			return;
		}
		destination.children[place].insertAdjacentElement('beforebegin', element);
	}

	// Функция возврата
	DynamicAdapt.prototype.moveBack = function (parent, element, index) {
		element.classList.remove(this.daClassname);
		if (parent.children[index] !== undefined) {
			parent.children[index].insertAdjacentElement('beforebegin', element);
		} else {
			parent.insertAdjacentElement('beforeend', element);
		}
	}

	// Функция получения индекса внутри родителя
	DynamicAdapt.prototype.indexInParent = function (parent, element) {
		const array = Array.prototype.slice.call(parent.children);
		return Array.prototype.indexOf.call(array, element);
	};

	// Функция сортировки массива по breakpoint и place
	// по возрастанию для this.type = min
	// по убыванию для this.type = max
	DynamicAdapt.prototype.arraySort = function (arr) {
		if (this.type === "min") {
			Array.prototype.sort.call(arr, function (a, b) {
				if (a.breakpoint === b.breakpoint) {
					if (a.place === b.place) {
						return 0;
					}

					if (a.place === "first" || b.place === "last") {
						return -1;
					}

					if (a.place === "last" || b.place === "first") {
						return 1;
					}

					return a.place - b.place;
				}

				return a.breakpoint - b.breakpoint;
			});
		} else {
			Array.prototype.sort.call(arr, function (a, b) {
				if (a.breakpoint === b.breakpoint) {
					if (a.place === b.place) {
						return 0;
					}

					if (a.place === "first" || b.place === "last") {
						return 1;
					}

					if (a.place === "last" || b.place === "first") {
						return -1;
					}

					return b.place - a.place;
				}

				return b.breakpoint - a.breakpoint;
			});
			return;
		}
	};

	const da = new DynamicAdapt("max");
	da.init();
}

export function animScroll() {
	const animItems = document.querySelectorAll('._anim-items')

	if (animItems.length > 0) {
		window.addEventListener('scroll', animOnScroll)

		function animOnScroll(params) {
			for (let index = 0; index < animItems.length; index++) {
				const animItem = animItems[index];
				const animItemHeight = animItem.offsetHeight;
				const animItemsOffset = offset(animItem).top;
				const animStart = 4;

				let animItemPoint = window.innerHeight - animItemHeight / animStart;

				if (animItemHeight > window.innerHeight) {
					animItemPoint = window.innerHeight - window.innerHeight / animStart;
				}

				if ((scrollY > animItemsOffset - animItemPoint) && scrollY < (animItemsOffset + animItemHeight)) {
					animItem.classList.add('_active')
				} else {
					if (!animItem.classList.contains('_anim-no-hide')) {
						animItem.classList.remove('_active')
					}
				}
			}
		}

		function offset(el) {
			var rect = el.getBoundingClientRect(),
				scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			return {
				top: rect.top + scrollTop,
				left: rect.left + scrollLeft
			}
		}
		setTimeout(() => {
			animOnScroll();
		}, 300);
	}
}

export function isTabs() {
	const tabsBtn = document.querySelectorAll('.choise__tab-title')
	const tabsItems = document.querySelectorAll('.choise__content')
	const select = document.getElementById('tab-select')

    if (tabsItems && tabsBtn && select) {
        tabsBtn.forEach(function (item) {
            item.addEventListener('click', function () {
                let currentBtn = item;

                let tabId = currentBtn.getAttribute('data-tab')
                let currentTab = document.querySelector(tabId)

                if (!currentBtn.classList.contains('_tab-active')) {
                    tabsBtn.forEach(function (item) {
                        item.classList.remove('_tab-active')
                    })
                    tabsItems.forEach(function (item) {
                        item.classList.remove('_tab-active')
                    })
                    currentBtn.classList.add("_tab-active")
                    currentTab.classList.add("_tab-active")
                }
            })
        })

        document.querySelector('.choise__tab-title').click()

        // select


        select.addEventListener("change", function(e) {
            e.preventDefault()

            let sTabId = select.options[select.selectedIndex].dataset.tab
            // console.log(sTabId)

            let selectTab = document.querySelector(sTabId)

            // console.log(selectTab);

            if (!selectTab.classList.contains('_tab-active')) {
                tabsItems.forEach(function (item) {
                    item.classList.remove('_tab-active')
                })
                selectTab.classList.add('_tab-active')
            }

        })
    }

}

export function isSlider() {

}

export function toTop() {
	const button = document.querySelector('.top');

const displayButton = () => {
    if (button) {
	window.addEventListener('scroll', () => {

		if (window.scrollY > 100) {
		button.style.display = "block";
		} else {
		button.style.display = "none";
		}
	});
	};
}
	if (button) {
        button.addEventListener("click", () => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    }
	const scrollToTop = () => {
};

displayButton();
scrollToTop();
}






