


$(document).ready(function() {
    $('#form-calculate-fee').submit(function(e) {
        e.preventDefault();
        const $this = $(this);
        let hasError = false;
        let errorFields = [];
        // console.log($this)
        // console.log($this.attr('action'))
        $('.broker__form-block').removeClass('has-error');
        errorFields = [];

        $.ajax({
            type: 'POST',
            url: $this.attr('action'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: "JSON",
            data: new FormData(this),
            contentType: false,
            processData: false,
            success: function(response) {
                // Update the response container with the result
                let html2 =  `<h2 class="tax-response-title">${ response.message }</h2>
                <div class="tax-response-blocks">

                    <div class="table-container">
                    <div class="table-row table-header">
                        <div class="table-cell">Збір</div>
                        <div class="table-cell">Сума</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Вартість авто</div>
                        <div class="table-cell">${ Math.fround(response.results.cost).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Ввізне мито</div>
                        <div class="table-cell">${ Math.fround(response.results.importDuty).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Акциз</div>
                        <div class="table-cell">${ Math.fround(response.results.excise).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">ПДВ</div>
                        <div class="table-cell">${ Math.fround(response.results.vat).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Країна (збір)</div>
                        <div class="table-cell">${ Math.fround(response.results.countryFee).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Брокер</div>
                        <div class="table-cell">${ Math.fround(response.results.brokerFee).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Swift переказ</div>
                        <div class="table-cell">${ Math.fround(response.results.swiftFee).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Вартість послуг</div>
                        <div class="table-cell">${ Math.fround(response.results.serviceFee).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell">Сертифікат</div>
                        <div class="table-cell">${ Math.fround(response.results.certificateFee).toFixed(1)} EUR</div>
                    </div>
                    <div class="table-row">
                        <div class="table-cell"><b>Всього</b></div>
                        <div class="table-cell">${ Math.fround(response.results.total).toFixed(1)} EUR</div>
                    </div>
                </div>
                </div>`
                $('#fee-response-container .fee-response-content').html(html2)
                $('#fee-response-container').addClass('active');
            },
            error: function(response) {
                const errors = response.responseJSON.errors;
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const errorField = $this.find('[name="' + key + '"]');
                        const formBlockWithError = errorField.closest('.broker__form-block');
                        formBlockWithError.addClass('has-error');

                        // Додаємо поле з помилкою до масиву помилкових полів
                        errorFields.push(formBlockWithError);
                    }
                }

                // Прокручуємо до першої помилки після того, як знайдемо всі помилки
                if (errorFields.length > 0) {
                    const firstErrorFieldOffset = errorFields[0].offset().top;
                    $('html, body').animate({ scrollTop: firstErrorFieldOffset }, 'slow');
                }
            }
        });
    });
    $('.broker__form-block input[type="text"], .broker__form-block select').on('focus', function() {
        $(this).closest('.broker__form-block').removeClass('has-error');
    });
});
