


$(document).ready(function() {
    $('#form-calculate-tax').submit(function(e) {
        e.preventDefault();
        const form = $(this).closest('form'); // Use $(this) instead of e
        const phoneInput = form.find('.phone-numero'); // Use .find() to get elements within the form
        const regex = /\+38\(\d{3}\)\d{3}-\d{2}-\d{2}/;
        const phoneValid = regex.test(phoneInput.val()); // Use .val() to get input value
        if (!phoneValid) {
            phoneInput.focus();
            return;
        }
        const $this = $(this);
        let hasError = false;
        let errorFields = [];
        // console.log($this)
        // console.log($this.attr('action'))
        $.ajax({
            type: 'POST',
            url: $this.attr('action'),
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: "JSON",
            data: new FormData(this),
            contentType: false,
            processData: false,
            success: function(response) {
                // Update the response container with the result
                let html2 =  `<h2 class="tax-response-title">${ response.message }</h2>
                <div class="tax-response-blocks">
                    <div class="tax-response-block">
                        <p>Ввізне мито: </p>
                        <span>${ Math.fround(response.results.importDuty).toFixed(1)} ГРН</span>
                    </div>
                    <div class="tax-response-block">
                        <p>ПДВ: </p>
                        <span>${Math.fround(response.results.vat).toFixed(1)  } ГРН</span>
                    </div>
                    <div class="tax-response-block">
                        <p>Акциз: </p>
                        <span>${Math.fround(response.results.excise).toFixed(1)  } ГРН</span>
                    </div>
                    <div class="tax-response-block">
                        <p>Всього: </p>
                        <span>${Math.fround(response.results.total).toFixed(1)  } ГРН</span>
                    </div>
                </div>`
                $('.broker__content-form').addClass('none-active');
                $('#tax-response-container .tax-response-content').html(html2 )
                $('#tax-response-container').addClass('active');
                const formOffset = $this.offset().top;
                $('html, body').animate({ scrollTop: formOffset }, 'slow');
            },
            error: function(response) {
                const errors = response.responseJSON.errors;
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const errorField = $this.find('[name="' + key + '"]');
                        const formBlockWithError = errorField.closest('.broker__form-block');
                        formBlockWithError.addClass('has-error');

                        // Додаємо поле з помилкою до масиву помилкових полів
                        errorFields.push(formBlockWithError);
                    }
                }

                // Прокручуємо до першої помилки після того, як знайдемо всі помилки
                if (errorFields.length > 0) {
                    const firstErrorFieldOffset = errorFields[0].offset().top;
                    $('html, body').animate({ scrollTop: firstErrorFieldOffset }, 'slow');
                }
            }
        });
    });
    $('.broker__form-block input[type="text"], .broker__form-block select').on('focus', function() {
        $(this).closest('.broker__form-block').removeClass('has-error');
    });
    $('#tax-response-container .retry').click(function() {
        $('#tax-response-container').removeClass('active');
        $('.broker__content-form').removeClass('none-active');
    });
});


